import BEM from 'tao-bem';

class AboutDigits extends BEM.Block {
	static get blockName() {
		return 'b-about-digits';
	}
	onInit() {
		//
	}
}

AboutDigits.register();

export default AboutDigits;