import BEM from 'tao-bem';

class MainText extends BEM.Block {
	static get blockName() {
		return 'b-main-text';
	}

	onInit() {
		// const it = this;
		var bg = this.elems('image').$el;
		bg.each( function() {
			var el = $(this).attr('data-bg');
			$(this).css('backgroundImage', `url('${el}')`);
		});
	}
}

MainText.register();

export default MainText;