import BEM from 'tao-bem';

class BreedingBlockServices extends BEM.Block {
	static get blockName() {
		return 'b-breeding-block-services';
	}

	onInit() {
		//
	}
}

BreedingBlockServices.register();

export default BreedingBlockServices;