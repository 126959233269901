import BEM from 'tao-bem';
import simpleParallax from 'simple-parallax-js';

class BlockVideo extends BEM.Block {
	static get blockName() {
		return 'b-block-video';
	}

	onInit() {
		var it = this;
		// var image = this.$el.attr('data-image');
		var img = this.$elem('images');
		img.addClass('dd');
		// img.attr('src', image);

		var video = this.elem('video').$el.attr('data-video');

		this.$el.click(function() {
			it.elem('wrapper').$el.css('pointer-events', 'none');
			it.elem('recording').$el.css('display', 'none');
			it.$el.css('background-image', 'none');
			it.elem('video').$el.css('display', 'block');
			it.elem('button-play').$el.css('display', 'none');
			it.elem('title').$el.css('display', 'none');
			it.elem('iframe').$el.prop('src', `https://www.youtube.com/embed/${video}?autoplay=1`);
		});

		if ($(window).width() > 749) {
			new simpleParallax(document.querySelectorAll('.b-block-video__images'), {
				delay: 0,
				orientation: 'down',
				scale: 1.4,
				// overflow: true,
				// customWrapper: '.b-block-video'
			});
		}
	}
}

BlockVideo.register();

export default BlockVideo;