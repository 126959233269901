import BEM from 'tao-bem';

class Steps extends BEM.Block {
	static get blockName() {
		return 'b-three_elements_drop_down';
	}
	onInit() {
		const it = this;
		const items = it.$el.find(`.${it.block}__item-desc`);
		const arrows = it.$el.find(`.${it.block}__arrow`);
		arrows.on('click', function () {
			const item = $(this).closest(`.${it.block}__item`);
			item.toggleClass('active')
			if (item.hasClass('active')) {
				item.find(`.${it.block}__item-hidden-desc`).show();
			} else {
				item.find(`.${it.block}__item-hidden-desc`).hide();
			}
		});

		// console.log($(`.${it.block}__desc-image`));

		// $(`.${it.block}__desc-image`).on('mouseover',function () {
		// 	console.log(1);
		// 	$(this).addClass('active');
		// });

		maxHeight()
		$(window).resize(() => {
			maxHeight()
		})

		function maxHeight() {
			if ($(window).width() > 749) {
				let maxHeight = 0;
				items.css('height', 'auto');
				items.each(function() {
					const thisHeight = $(this).height();
					if (thisHeight > maxHeight) {
						maxHeight = thisHeight;
					}
				});

				items.css('height', maxHeight)
			}
		}
	}
}

Steps.register();

export default Steps;