import BEM from "tao-bem";
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

class Customers extends BEM.Block {
	static get blockName() {
		return 'b-customers';
	}

	onInit() {
		const it = this;

		window.loading = false;

		click_open_menu();

		filter_change();

		$('body').on('click',function (e) {
			var target = $(e.target);
			if(!target.is('.b-customers__filter-text') && !target.is('.b-customers__filter-list-item') ) {
				$('.b-customers__filter-item').removeClass('b-customers__filter-item--opened');
				$('.b-customers__filter-text').removeClass('b-customers__filter-text--opened');
				$('.b-customers__filter-list').slideUp("slow").removeClass('b-customers__filter-list--opened');
			}
		});

		//ajax подгрузка при удалении/добавлении фильтра
		$('.b-customers__filter-items-selected').on('addItem removeItem',function () {
			change_filter_load_ajax();
			$('.b-clients__item').addClass('opacity');
		});

		let container = $(`.${it.block}__filter-list-container`);
		container.each( function() {
			// const $elem = $(this);

			new PerfectScrollbar(this, {
				maxScrollbarLength: 100,
				// suppressScrollX: true,
				// speed: 100,
			});
		})
	}
}

function filter_change() {
	let filterItemsContainer = $('.b-customers__filter-items-selected');

	//добавление выбранных опций в b-customers__selected-item
	$('.b-customers__filter-list-item').on('click', function () {

		if ($(this).hasClass('b-customers__filter-list-item--selected')) {
			filterItemsContainer
				.find("[data-code='" + $(this).data('code') + "']")
				.remove();
			$('.b-customers__filter-items-selected').trigger('removeItem');
		} else {
			filterItemsContainer
				.append('<div class="b-customers__selected-item" data-id="' + $(this).data('id') + '" data-code="' + $(this).data('code') + '" data-section="' + $(this).data('section') + '"><div class="b-customers__selected-item-text">' + $(this).text() + '</div><div class="b-customers__selected-item-close"></div></div>')
				.trigger('addItem');
		}

		$(this).toggleClass('b-customers__filter-list-item--selected');
	});

	//удаление выбранных опций из b-customers__filter-items-selected и b-customers__filter-item
	$('.b-customers__filter-items-selected').on('click', '.b-customers__selected-item .b-customers__selected-item-close', function () {
		let dataAttribute = $(this).parent().data('code');
		$('.b-customers__filter')
			.find("[data-code='" + dataAttribute + "']")
			.removeClass('b-customers__filter-list-item--selected');
		$(this).parent().remove();
		$('.b-customers__filter-items-selected').trigger('removeItem');
	});
}
function click_open_menu() {
	$('.b-customers__filter-text').on('click', function () {
		$(this).toggleClass('b-customers__filter-text--opened');
		$(this).parent().toggleClass('b-customers__filter-item--opened');
		$(this).parent().find('.b-customers__filter-list').slideToggle("slow").toggleClass('b-customers__filter-list--opened');
	});
}
function get_filter_items() {
	var items = {
		'SERVICES': [],
		'p_branch': [],
	};
	var $filterItems = $('.b-customers__filter-items-selected');

	$.each($filterItems.find('.b-customers__selected-item'),function () {
		var section = $(this).data('section');
		if(section == 'SERVICES') {
			items[section].push(($(this).data('id')));
		} else {
			items[section].push(($(this).text()));
		}
	});

	return items;
}

function change_filter_load_ajax() {

	var $ajax_items_list = $('.b-customers__list');
	var parms = {
		filter: get_filter_items(),
	};

	var $filterItems = $('.b-customers__filter-items-selected');
	var c = 0;
	$.each($filterItems.find('.b-customers__selected-item'),function () {
		c++;
	});

	$ajax_items_list.find('.tao-list').html('').append('<div class="b-preloader"><div class="b-preloader__image"><img src="/images/main_redisign/pages/preloader_white.png" alt=""></div></div>');

	var l = $('.b-customers').data('lang');
	var lang = '';
	if (l != 'ru') {
		lang = '/' + l;
	}

	window.loading = true;
	$.get({
		url: lang + '/ajax/customers.php',
		data: parms,
		success: function (data) {
			if($(data).length < 2) {
				$ajax_items_list.find('.tao-list').html($(data).html().replace(/class="b-clients__item/g, 'class="b-clients__item opacity '));
			} else {
				$ajax_items_list.find('.tao-list').html($(data).siblings('.tao-list').html().replace(/class="b-clients__item/g, 'class="b-clients__item opacity '));
			}
			if (c > 0) {
				$('.b-customers__pagination').hide();
			}
			else {
				$('.b-customers__pagination').show();
			}
			window.loading = false;
		},
	});
}

Customers.register();

export default Customers;