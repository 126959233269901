import BEM from 'tao-bem';

class BlockTypeDisk extends BEM.Block {
	static get blockName() {
		return 'b-block-type-disk';
	}
	onInit() {
		//
	}
}

BlockTypeDisk.register();

export default BlockTypeDisk;