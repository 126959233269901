import BEM from 'tao-bem';

class ResourceConfigurator extends BEM.Block {
	static get blockName() {
		return 'b-resource-configurator';
	}

	onInit() {
		//
	}
}

ResourceConfigurator.register();

export default ResourceConfigurator;