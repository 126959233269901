import BEM from 'tao-bem';

class MainServices extends BEM.Block {
	static get blockName() {
		return 'b-main-services';
	}

	onInit() {
		//
	}
}

MainServices.register();

export default MainServices;