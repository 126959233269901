import BEM from 'tao-bem';

class ServiceEvents extends BEM.Block {
	static get blockName() {
		return 'b-service_events';
	}
	onInit() {
		// const it = this;
		var width = $(window).outerWidth();
		if (width >= 600) {
			$('.b-service_events__image').each(function(){
				var bg = $(this).data('bg');
				if (bg) {
					$(this).css('background-image', "url('" + bg + "')");
				}
			});
		}
	}
}

ServiceEvents.register();

export default ServiceEvents;