import BEM from 'tao-bem';
import simpleParallax from 'simple-parallax-js';

class BanerImage extends BEM.Block {
	static get blockName() {
		return 'b-baner-image';
	}
	onInit() {

		const it = this;
		var image = it.elem('image').$el;
		const image_first = it.$el.attr('data-first');
		const image_second = it.$el.attr('data-second');
		const image_three = it.$el.attr('data-three');
		// var bg = this.elems("item").$el;

		if ($(window).width() > 1000) {
			image.attr('src', image_first);
		} else if($(window).width() < 1000 && $(window).width() > 400) {
			if (image_second) {
				image.attr('src', image_second);
			} else {
				image.attr('src', image_first);
			}

		} else {
			if (image_three) {
				image.attr('src', image_three);
			} else if(image_second) {
				image.attr('src', image_second);
			} else {
				image.attr('src', image_first);
			}
		}

		new simpleParallax(document.querySelectorAll('.b-baner-image__image'), {
			delay: 0,
			orientation: 'down',
			scale: 1,
			// overflow: true,
			// customWrapper: '.b-baner-image'
		});
	}
}

BanerImage.register();

export default BanerImage;