import BEM from 'tao-bem';

class DigitsBlueBg extends BEM.Block {
	static get blockName() {
		return 'b-digits-blue-bg';
	}
	onInit() {
		//
	}
}

DigitsBlueBg.register();

export default DigitsBlueBg;