import BEM from 'tao-bem';

class KeyFeature extends BEM.Block {
	static get blockName() {
		return 'b-information-about';
	}

	onInit() {
		//
	}
}

KeyFeature.register();

export default KeyFeature;