import BEM from 'tao-bem';

class QuestionsList extends BEM.Block {
	static get blockName() {
		return 'b-questions-list';
	}

	onInit() {
		var it = this;

		it.elems('item').$el.click(function() {
			$(this).toggleClass('active');
		});
	}
}

QuestionsList.register();

export default QuestionsList;