import BEM from 'tao-bem';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

class PictureBlock extends BEM.Block {
	static get blockName() {
		return 'b-picture-block';
	}
	onInit() {
		var img = this.elem('img').$el;
		var image = this.elem('image').$el;
		var first = image.attr('data-first');
		var second = image.attr('data-second');
		var three = image.attr('data-three');
		var width = $(window).outerWidth();

		var isset = false;
		if (three && width <= 640) {
			img.attr("src", three);
			isset = true;
		}
		else if (second && width > 640 && width < 999) {
			img.attr("src", second);
			isset = true;
		}

		if (!isset && first) {
			img.attr("src", first);
		}

		// Fancybox.getInstance().getSlide().Panzoom.toggleZoom();

		Fancybox.bind("[data-fancybox]", {
			Image: {
				zoom: true,
			},
		});
	}
}

PictureBlock.register();

export default PictureBlock;