import BEM from 'tao-bem';
import Swiper, {Pagination, Navigation} from 'swiper';
import 'swiper/css/bundle';
import 'perfect-scrollbar/css/perfect-scrollbar.css';


class Experts extends BEM.Block {
	static get blockName() {
		return 'b-experts';
	}
	onInit() {
		var it = this;
		new Swiper(`.${it.block}__swiper`, {
			direction: "horizontal",
			slidesPerView: "auto",
			spaceBetween: 32,
			modules: [Pagination, Navigation],
			pagination: {
				el: `.${it.block}__pagination`,
				clickable: true,
			},
			navigation: {
				nextEl: `.${it.block}__swiper-next`,
				prevEl: `.${it.block}__swiper-prev`,
			},
			breakpoints: {
				640: {
					slidesPerView: 2,
					spaceBetween: 120,
				},
				800: {
					slidesPerView: 3,
					spaceBetween: 58,
				},

				1440: {
					slidesPerView: 4,
					spaceBetween: 58,
				},
			}
		});
	}
}

Experts.register();

export default Experts;