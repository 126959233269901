import BEM from 'tao-bem';
import Swiper, {Pagination, Navigation} from 'swiper';
import 'swiper/css/bundle';

class CrocNews extends BEM.Block {
	static get blockName() {
		return 'b-certificates';
	}

	onInit() {
		var it = this;

		new Swiper(`.certificatesSwiper`, {
			slidesPerView: 1,
			spaceBetween: 0,
			modules: [Pagination, Navigation],
			pagination: {
				el: `.${it.block}__pagination`,
				clickable: true,
			},
			navigation: {
				nextEl: `.${it.block}__swiper-next`,
				prevEl: `.${it.block}__swiper-prev`,
			},

			breakpoints: {
				320: {
					slidesPerView: "auto",
					spaceBetween: 0,
				},
				640: {
					slidesPerView: 2,
					spaceBetween: 94,
				},
				// when window width is >= 1000px
				1000: {
					slidesPerView: 4,
					spaceBetween: 32,
				}
			}
		});
	}
}

CrocNews.register();

export default CrocNews;