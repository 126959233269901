import "@babel/polyfill";

import 'style/layout/work';
import 'block/common';
import 'block/main';
import 'block/content';
import 'block/blog-articles-detail';
import 'block/blog-articles-list';
import 'block/blog';
import 'component';
import UtmTracker from './UtmTracker.js'

(function () {
	"use strict";
	$(function () {
		const exceptions = [
			'mailto',
			'tel',
			'#',
			'https'
		]

		if (Object.keys(UtmTracker.getUtmParams()).length === 0) {
			const utmItems = UtmTracker.getUtmParamsFromUrl();
			UtmTracker.setUtmParams(utmItems);
		}

		const queryString = UtmTracker.getUtmQueryString(UtmTracker.getUtmParams());

		if (queryString) {
			$('a').each(function () {
				const elem = $(this);
				const href = $(this).attr('href');
				if (href) {
					if (!exceptions.some(e=>href.includes(e))) {
						if (href.includes('?')) {
							// console.log(href);
							elem.attr('href', href+'&'+ queryString)
						} else {
							elem.attr('href', href+'?'+ queryString)
						}
					}
				}
			})
		}

		window.addEventListener('beforeunload', () => {
			UtmTracker.clearUtmParams();
		});



		// Плавный скролл к якорю с учётом высоты абсолют/фиксед шапки
		$('body').on('click', 'a', function() {
			var href = $(this).attr('href');
			if(href == '#questions' || href == '#calculator' || href == '#special') {
				if (href && href.indexOf('#') == 0) {
					var id = href.substr(1);
					var target = $('[id="' + id + '"]');

					if (target.length == 0) {
						target = $('[name="' + id + '"]');
					}
					if (target.length > 0) {
						var curScrollTop = $(target).offset().top;

						if (curScrollTop > 0) {
							$("html, body").animate({ scrollTop: curScrollTop }, 1000);
						}
					}

					window.location.hash = id;

					return false;
				}
			}
		});

		let hash = window.location.hash;
		if (hash) {
			var id = hash.substr(1);
			var target = $('[id="' + id + '"]');

			if (target.length == 0) {
				target = $('[name="' + id + '"]');
			}
			var curScrollTop = target.offset().top;

			setTimeout(function () {
				if (curScrollTop > 0) {
					target[0].scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					})
				}
			}, 200)

		}

		$("table").each(function(){
			var p = $(this).parents('.scroll-table');
			var notable = false;
			if ($(this).hasClass('notable')) {
				notable = true;
			}
			if (p.length == 0 && !notable) {
				$(this).wrap('<div class="scroll-table"></div>');
			}
		});
	})
})();
