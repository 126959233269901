import BEM from 'tao-bem';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css/bundle';

class BlockServices extends BEM.Block {
	static get blockName() {
		return 'b-block-services';
	}

	onInit() {
		const it = this;
		new Swiper(it.$el.find(`.${it.name}__servicesSwiper`)[0], {
			slidesPerView: 3,
			spaceBetween: 32,
			modules: [Pagination, Navigation],
			pagination: {
				el: it.$el.find(`.${it.name}__pagination`)[0],
				clickable: true,
			},
			navigation: {
				nextEl: it.$el.find(`.${it.name}__swiper-next`)[0],
				prevEl: it.$el.find(`.${it.name}__swiper-prev`)[0],
			},
			breakpoints: {
				320: {
					slidesPerView: 1,
					spaceBetween: 24,
					slidesPerGroup: 1,
				},
				700: {
					slidesPerView: 2,
					spaceBetween: 32,
					slidesPerGroup: 1,
				},
				1200: {
					slidesPerView: 3,
					spaceBetween: 32,
					slidesPerGroup: 1,
				}
			}
		});
	}
}


BlockServices.register();

export default BlockServices;