import BEM from 'tao-bem';
import Swiper, {Pagination} from 'swiper';
import 'swiper/css/bundle';
import 'jquery-match-height';
import simpleParallax from 'simple-parallax-js';

class MainBaner extends BEM.Block {
	static get blockName() {
		return 'b-main-baners';
	}

	onInit() {
		// var bg = this.elems('item').$el;
		// bg.each( function() {
		// 	var el = $(this).attr('data-bg');
		// 	$(this).css('backgroundImage', `url('${el}')`);
		// });
		var it = this;
		new Swiper(".baner-slider", {
			modules: [Pagination],
			pagination: {
				el: `.${it.block}__pagination`,
				clickable: true,
			},
		});

		if ($(window).width() > 749) {

			new simpleParallax(document.querySelectorAll('.b-main-baners__image'), {
				delay: 0,
				orientation: 'down',
				scale: 1.5,
			});
		}
	}
}

MainBaner.register();

export default MainBaner;