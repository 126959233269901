import BEM from 'tao-bem';

class MainDigits extends BEM.Block {
	static get blockName() {
		return 'b-main_digits';
	}

	onInit() {
		//
	}
}

MainDigits.register();

export default MainDigits;