import BEM from 'tao-bem';
import Swiper, {Pagination} from 'swiper';
import 'swiper/css/bundle';

class MainWhy extends BEM.Block {
	static get blockName() {
		return 'b-main-why';
	}

	onInit() {
		const it = this;
		let swiper = false;
		if ($(window).width() < 640) {
			swiper = it.SwiperInit(`.whySwiper`);
		}

		window.addEventListener('resize', () => {
			if ($(window).width() < 640) {
				if (!swiper) {
					swiper = it.SwiperInit(`.whySwiper`);
				}
			} else {
				if (swiper) {
					swiper.destroy(true, true);
				}
				swiper = false;
			}
		});
	}

	SwiperInit(slider_class)
	{
		const it = this;
		return new Swiper(slider_class, {
			modules: [Pagination],
			pagination: {
				el: `.${it.block}__pagination`,
				clickable: true,
			},
			observer: true,
		});
	}
}

MainWhy.register();

export default MainWhy;