import BEM from 'tao-bem';
import Swiper, {Navigation, Pagination} from 'swiper';
import 'swiper/css/bundle';
import 'jquery-match-height';

class MainNews extends BEM.Block {
	static get blockName() {
		return 'b-main-news';
	}

	onInit() {
		var it = this;
		// var el = this.elems('content').$el;
		// el.matchHeight();
		var bg = it.$el.attr('data-bg');
		if(bg) {
			it.$el.addClass('background');
		}

		new Swiper(".newsMainSwiper", {
			modules: [Navigation, Pagination],
			spaceBetween: 32,
			speed: 600,
			pagination: {
				el: `.${it.block}__pagination`,
				clickable: true,
			},
			navigation: {
				nextEl: ".swiper-next",
				prevEl: ".swiper-prev",
			},

			breakpoints: {
				320: {
					slidesPerView: 1,
					slidesPerGroup: 1,
				},
				800: {
					slidesPerView: 2,
					slidesPerGroup: 1,
				},
				// when window width is >= 1000px
				1200: {
					slidesPerView: 3,
					slidesPerGroup: 1,
				}
			}
		});
	}
}

MainNews.register();

export default MainNews;