import BEM from 'tao-bem';

class MainDatacenters extends BEM.Block {
	static get blockName() {
		return 'b-main-datacenters';
	}

	onInit() {
		const it = this;
		const image_first = it.$el.attr('data-first');
		const image_second = it.$el.attr('data-second');
		const image_three = it.$el.attr('data-three');
		// var bg = this.elems("item").$el;

		if ($(window).width() > 1000) {
			it.$el.css("backgroundImage", `url('${image_first}')`);
		} else if($(window).width() < 1000 && $(window).width() > 400) {
			if (image_second) {
				it.$el.css("backgroundImage", `url('${image_second}')`);
			} else {
				it.$el.css("backgroundImage", `url('${image_first}')`);
			}
		} else {
			if (image_three) {
				it.$el.css("backgroundImage", `url('${image_three}')`);
			} else if(image_second) {
				it.$el.css("backgroundImage", `url('${image_second}')`);
			} else {
				it.$el.css("backgroundImage", `url('${image_first}')`);
			}
		}
	}
}

MainDatacenters.register();

export default MainDatacenters;