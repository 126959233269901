import BEM from 'tao-bem';

class BlogArticlesList extends BEM.Block {
	static get blockName() {
		return 'b-blog-articles-list';
	}

	onInit() {
		//
	}
}


BlogArticlesList.register();

export default BlogArticlesList;