import BEM from 'tao-bem';
import Swiper, {Navigation, Pagination} from 'swiper';
import 'swiper/css/bundle';
import 'jquery-match-height';

class CrocNews extends BEM.Block {
	static get blockName() {
		return 'b-croc-news';
	}

	onInit() {
		var it = this;
		var el = this.elems('content').$el;
		el.matchHeight();

		new Swiper(".newsSwiper", {
			// slidesPerView: 3,
			// spaceBetween: 24,
			modules: [Navigation, Pagination],
			pagination: {
				el: `.${it.block}__pagination`,
				clickable: true,
			},
			navigation: {
				nextEl: ".swiper-next",
				prevEl: ".swiper-prev",
			},

			breakpoints: {
				320: {
					slidesPerView: 1,
					// spaceBetween: 24,
					slidesPerGroup: 1,
				},
				640: {
					slidesPerView: 2,
					// spaceBetween: 32,
					slidesPerGroup: 1,
				},
				// when window width is >= 1000px
				1000: {
					slidesPerView: 3,
					// spaceBetween: 32,
					slidesPerGroup: 1,
				}
			}
		});
	}
}

CrocNews.register();

export default CrocNews;